import { Observable } from 'rxjs';

/**
 * Waits for the specified element to become available in the DOM.
 * @param selector The CSS selector of the element to wait for.
 * @returns An Observable that emits the element once it's available.
 */
export const waitForElement = (selector: string) => {
  return new Observable((observer) => {
    if (document.querySelector(selector)) {
      observer.next(document.querySelector(selector));
      observer.complete();
    }

    const mutationObserver = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        mutationObserver.disconnect();
        observer.next(document.querySelector(selector));
        observer.complete();
      }
    });

    mutationObserver.observe(document.body, {
      childList: true,
      subtree: true
    });

    return () => {
      mutationObserver.disconnect();
    };
  });
};

export const FEATURE_NAME = 'intro';

export interface IntroState {
  introInfo?: IntroInfoModel;
  loaded?: boolean;
  loading: boolean;
  error?: Error;
}

export interface IntroInfoModel {
  shownIntro: Map<IntroType, boolean>;
}

export enum IntroType {
  PtDashboard = 'PtDashboard',
  CustomerDashboard = 'CustomerDashboard'
}

<ng-container>
  <div class="step-resume-container p-24">
    @for (stepItem of formSteps; track $index) {
      <div class="step-resume row m-l-4" [ngClass]="{ 'm-t-12': !$first }">
        <div class="col-auto">
          @if (getFormGroup(stepItem.formGroupName).valid && step >= $index) {
            <i-tabler name="circle-check" style="color: #5d87ff"></i-tabler>
          } @else {
            <i-tabler name="circle-dashed"></i-tabler>
          }
        </div>
        <div class="col">
          <h4 class="m-0" [ngClass]="{ 'text-primary': step === $index }">{{ stepItem.label }}</h4>
          <small>{{ getStepValueLabel(stepItem.formGroupName) }}</small>
        </div>
      </div>
    }
  </div>
  <div class="m-t-48 d-flex justify-content-between">
    <button mat-stroked-button (click)="back.emit()" [disabled]="step === 0">Indietro</button>
    @if (step !== formSteps.length - 1) {
      <button mat-flat-button color="primary" (click)="next.emit()" [disabled]="!canGoNext()">Avanti</button>
    } @else {
      <button mat-flat-button color="primary" (click)="save.emit()" [disabled]="dataForm.invalid">Salva</button>
    }
  </div>
</ng-container>

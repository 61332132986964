import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, first, map, withLatestFrom } from 'rxjs/operators';
import { IntroActions } from './intro.actions';
import { Store } from '@ngrx/store';
import { selectCurrentRole } from 'src/app/core/auth/data/auth.selectors';
import { IntroDataService } from './intro-data.service';
import { of } from 'rxjs';
import { selectIntroInfo } from './intro.selectors';

@Injectable()
export class IntroEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private introService: IntroDataService
  ) {}

  startCockpitIntro$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntroActions.startCockpitIntro),
        withLatestFrom(this.store.select(selectCurrentRole), this.store.select(selectIntroInfo)),
        exhaustMap(([, role, introInfo]) => {
          return this.introService.startCockpitIntro(role, introInfo);
        })
      ),
    {
      dispatch: false
    }
  );

  getIntroInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntroActions.getIntroInfo),
      exhaustMap(() => {
        return this.introService.getIntroInfo().pipe(
          first(),
          map((introInfo) => IntroActions.getIntroInfoSuccess({ introInfo: introInfo })),
          catchError((error) => of(IntroActions.getIntroInfoError({ error: error })))
        );
      })
    )
  );

  saveIntroInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntroActions.saveIntroInfo),
      exhaustMap((action) => {
        return this.introService.saveIntroInfo(action.introType).pipe(
          first(),
          map(() => IntroActions.saveIntroInfoSuccess({ introType: action.introType })),
          catchError((error) => of(IntroActions.saveIntroInfoError({ error: error })))
        );
      })
    )
  );
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IntroActions } from './intro.actions';
import { IntroType } from './intro.model';
import { CanActivate } from '@angular/router';
import { selectLoaded, selectLoading } from './intro.selectors';
import { map, withLatestFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntroService implements CanActivate {
  readonly loading$ = this.store.select(selectLoading);
  readonly loaded$ = this.store.select(selectLoaded);

  constructor(private store: Store) {}

  canActivate() {
    return this.loaded$.pipe(
      withLatestFrom(this.loading$),
      map(([loaded, loading]) => {
        if (!loading && !loaded) {
          this.store.dispatch(IntroActions.getIntroInfo());
        }
        return true;
      })
    );
  }

  startTutorial() {
    this.store.dispatch(IntroActions.startCockpitIntro());
  }

  saveIntroInfo(introType: IntroType) {
    this.store.dispatch(IntroActions.saveIntroInfo({ introType: introType }));
  }
}

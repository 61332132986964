import { AuthenticationService } from 'src/app/core/auth/data/authentication.service';
import { Component } from '@angular/core';
import Userback, { UserbackOptions, UserbackWidget } from '@userback/widget';
import { tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'PT Connect';

  userbackWidget: UserbackWidget;

  constructor(private authenticationService: AuthenticationService) {
    this.authenticationService.user$
      .pipe(
        takeUntilDestroyed(),
        tap((user) => {
          if (user) {
            const options: UserbackOptions = {
              name: user.displayName,
              email: user.email
            };

            Userback('A-neOcqHnfMB50EQSDHFQpBtkDx', options)
              .then((userbackWidget) => (this.userbackWidget = userbackWidget))
              .catch((err) => console.error('Error initializing Userback. ', err));
          } else {
            this.userbackWidget?.destroy();
          }
        })
      )
      .subscribe(() => {});
  }
}

import { Injectable } from '@angular/core';
import { UserRole } from 'src/app/core/auth/data/auth.model';
import { waitForElement } from 'src/app/core/utils/wait-for-element';
import { of, tap } from 'rxjs';
import introJs from 'intro.js/';
import { IntroStep } from 'intro.js/src/core/steps';
import { HttpClient } from '@angular/common/http';
import { IntroInfoModel, IntroType } from './intro.model';
import { Store } from '@ngrx/store';
import { IntroActions } from './intro.actions';

@Injectable({
  providedIn: 'root'
})
export class IntroDataService {
  private introJs = introJs();

  constructor(
    private httpClient: HttpClient,
    private store: Store
  ) {}

  getIntroInfo() {
    return this.httpClient.get<IntroInfoModel>('/api/intro');
  }

  saveIntroInfo(introType: IntroType) {
    return this.httpClient.post<void>('/api/intro', {
      introType: introType,
      shown: true
    });
  }

  startCockpitIntro(role: UserRole, introInfo: IntroInfoModel) {
    const introType = role === UserRole.PT ? IntroType.PtDashboard : IntroType.CustomerDashboard;

    if (this.alreadyShown(introInfo, introType)) {
      return of(null);
    }

    return waitForElement('#main-sidebar').pipe(
      tap(() => {
        this.toggleBodyClass();

        void this.introJs
          .setOptions({
            steps: role === UserRole.Customer ? this.getCustomerSteps() : this.getPTSteps(),
            showBullets: false,
            showProgress: true,
            exitOnOverlayClick: false,
            disableInteraction: true,
            scrollToElement: true,
            nextLabel: 'Avanti',
            prevLabel: 'Indietro',
            doneLabel: 'Fine',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            progressBarAdditionalClass: 'bg-primary' as any
          })
          .onexit(() => {
            this.toggleBodyClass();
            this.store.dispatch(IntroActions.saveIntroInfo({ introType: introType }));
          })
          .start();
      })
    );
  }
  alreadyShown(introInfo: IntroInfoModel, introType: IntroType): boolean {
    if (introInfo.shownIntro[introType]) {
      return true;
    }
    return false;
  }

  private toggleBodyClass(): void {
    document.body.classList.toggle('introjs-active');
  }

  private getCustomerSteps(): IntroStep[] {
    return [
      {
        title: 'Benvenuto! 👋',
        intro: 'Faremo un veloce tour insieme per vedere le principali funzionalità di PT Connect',
        scrollTo: 'off',
        step: 0,
        position: 'floating'
      },
      {
        element: '#insert-data-item',
        title: 'Dati giornalieri',
        intro:
          'Tramite questo bottone potrai inserire informazioni come peso, calorie e molto altro. Le informazioni inserite saranno utili al tuo coach!',
        position: 'bottom',
        scrollTo: 'off',
        step: 1
      },
      {
        element: '#requests-menu-item',
        title: 'Richieste',
        intro: 'Qui potrai vedere tutte le richieste di collaborazione che i tuoi PT ti invieranno',
        position: 'bottom',
        scrollTo: 'off',
        step: 2
      },
      {
        element: '#main-sidebar-item-customer-collaborations',
        title: 'Collaborazioni',
        intro: 'Una volta accettata una richiesta di collaborazione, potrai ritrovarla qui',
        position: 'right',
        scrollTo: 'off',
        step: 3
      },
      {
        element: '#main-sidebar-item-checks',
        title: 'Checks',
        intro: 'Tramite questa voce di menù potrai vedere i tuoi prossimi check e quelli passati',
        position: 'right',
        scrollTo: 'off',
        step: 4
      }
    ];
  }

  private getPTSteps(): IntroStep[] {
    return [
      {
        title: 'Benvenuto! 👋',
        intro: 'Faremo un veloce tour insieme per vedere le principali funzionalità di PT Connect',
        position: 'floating',
        scrollTo: 'off',
        step: 0
      },
      {
        element: '#main-sidebar-item-pt-collaborations',
        title: 'Collaborazioni',
        intro: 'Cliccando qui potrai vedere tutte le tue collaborazioni. Potrai inoltre crearne di nuove!',
        position: 'right',
        scrollTo: 'off',
        step: 1
      }
    ];
  }
}

<div class="flex-layout">
  <app-branding></app-branding>
  @if (role$ | async; as role) {
    <mat-nav-list id="main-sidebar" class="sidebar-list">
      @for (item of navItems; track item) {
        <app-nav-item [id]="'main-sidebar-item-' + item.id" [item]="item" [role]="role"></app-nav-item>
      }
    </mat-nav-list>
  }
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { IntroEffects } from './store/intro.effects';
import { introReducer } from './store/intro.reducer';
import { FEATURE_NAME } from './store/intro.model';
import { StoreModule } from '@ngrx/store';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature(FEATURE_NAME, introReducer), EffectsModule.forFeature([IntroEffects])]
})
export class IntroNotificationModule {}

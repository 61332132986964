import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FEATURE_NAME, IntroState } from './intro.model';

export const selectFeature = createFeatureSelector<IntroState>(FEATURE_NAME);

export const selectIntroInfo = createSelector(selectFeature, (state) => {
  return state.introInfo;
});

export const selectLoading = createSelector(selectFeature, (state) => {
  return state.loading;
});

export const selectLoaded = createSelector(selectFeature, (state) => {
  return state.loaded;
});

import { AfterViewInit, Component } from '@angular/core';
import { navItems } from './sidebar-data';
import { NavService } from '../../../navigation/nav.service';
import { AuthenticationService } from 'src/app/core/auth/data/authentication.service';
import { IntroService } from 'src/app/features/intro-notification/store/intro.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements AfterViewInit {
  navItems = navItems;
  role$ = this.authService.role$;

  constructor(
    public navService: NavService,
    private authService: AuthenticationService,
    private introNotificationService: IntroService
  ) {}

  ngAfterViewInit() {
    this.introNotificationService.startTutorial();
  }
}

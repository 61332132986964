<mat-toolbar class="topbar">
  <!-- Mobile Menu -->
  @if (!showToggle) {
    <button mat-icon-button (click)="toggleMobileNav.emit()">
      <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
    </button>
  }
  <!-- --------------------------------------------------------------- -->

  @if (user$ | async; as user) {
    <app-notification-menu-item></app-notification-menu-item>
  }

  <span class="flex-1-auto"></span>

  <!-- --------------------------------------------------------------- -->
  <!-- profile Dropdown -->
  <!-- --------------------------------------------------------------- -->

  @if (user$ | async; as user) {
    @if ((role$ | async) === UserRole.Customer) {
      <button class="m-r-12" id="insert-data-item" mat-flat-button color="primary" (click)="showDataForm()">Inserisci i dati</button>
      <app-requests-menu-item></app-requests-menu-item>
    }
    <button mat-icon-button [matMenuTriggerFor]="profilemenu" aria-label="Profilo">
      <img
        referrerpolicy="no-referrer"
        [ngSrc]="photoURL$ | async | defaultImg"
        class="rounded-circle object-cover"
        width="35"
        height="35"
        priority
      />
    </button>
    <mat-menu #profilemenu="matMenu" class="topbar-dd cardWithShadow">
      <div mat-menu-item>{{ user.displayName }}</div>
      <a mat-menu-item [routerLink]="['/user-profile']">
        <mat-icon class="d-flex align-items-center"><i-tabler name="user" class="icon-18 d-flex"></i-tabler></mat-icon>
        Profilo
      </a>
      @if ((hasMultipleRoles$ | async) === true) {
        @if (role$ | async; as role) {
          <button mat-menu-item (click)="selectRole(role === UserRole.Customer ? UserRole.PT : UserRole.Customer)">
            <mat-icon class="d-flex align-items-center"><i-tabler name="switch" class="icon-18 d-flex"></i-tabler></mat-icon>
            {{ role === UserRole.Customer ? 'Modalità PT' : 'Modalità cliente' }}
          </button>
        }
      }

      <div class="p-x-12 m-t-12">
        <button (click)="logout()" mat-stroked-button color="primary" class="w-100">Logout</button>
      </div>
    </mat-menu>
  }
</mat-toolbar>

<ng-template #dataTemplate>
  <h2 mat-dialog-title>Registra i tuoi dati</h2>
  <mat-dialog-content>
    <app-insert-data-form></app-insert-data-form>
  </mat-dialog-content>
</ng-template>

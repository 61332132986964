import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IntroInfoModel, IntroType } from './intro.model';

export const IntroActions = createActionGroup({
  source: 'Intro',
  events: {
    startCockpitIntro: emptyProps(),
    getIntroInfo: emptyProps(),
    getIntroInfoSuccess: props<{ introInfo: IntroInfoModel }>(),
    getIntroInfoError: props<{ error: Error }>(),
    saveIntroInfo: props<{ introType: IntroType }>(),
    saveIntroInfoSuccess: props<{ introType: IntroType }>(),
    saveIntroInfoError: props<{ error: Error }>()
  }
});

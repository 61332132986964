import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlankComponent } from './core/layouts/blank/blank.component';
import { FullComponent } from './core/layouts/full/full.component';
import { canActivate, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { CompleteUserGuard } from './core/guards/complete-user.guard';
import { RoleGuard } from './core/guards/role.guard';
import { UserRole } from './core/auth/data/auth.model';
import { IntroService } from './features/intro-notification/store/intro.service';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/authentication/login']);

const canActivateFirebase = canActivate(redirectUnauthorizedToLogin);

const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: ([IntroService] as unknown[]).concat(canActivateFirebase.canActivate),
    data: canActivateFirebase.data,
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        data: { role: UserRole.PT },
        loadChildren: () => import('./features/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [CompleteUserGuard, RoleGuard]
      },
      {
        path: 'collaborations',
        data: {},
        loadChildren: () => import('./features/collaborations/collaborations.module').then((m) => m.CollaborationsModule),
        canActivate: [CompleteUserGuard, RoleGuard]
      },
      {
        path: 'collaborations/:id',
        data: {},
        loadChildren: () => import('./features/collaboration-detail/collaboration-detail.module').then((m) => m.CollaborationDetailModule),
        canActivate: [CompleteUserGuard, RoleGuard]
      },
      {
        path: 'user-profile',
        loadChildren: () => import('./features/user-profile/user-profile.module').then((m) => m.UserProfileModule),
        canActivate: [CompleteUserGuard, RoleGuard]
      },
      {
        path: 'customer-dashboard',
        data: { role: UserRole.Customer },
        loadChildren: () => import('./features/customer-dashboard/customer-dashboard.module').then((m) => m.CustomerDashboardModule),
        canActivate: [CompleteUserGuard, RoleGuard]
      },
      {
        path: 'checks',
        data: { role: UserRole.Customer },
        loadChildren: () => import('./features/checks/checks.module').then((m) => m.ChecksModule),
        canActivate: [CompleteUserGuard, RoleGuard]
      },
      {
        path: 'check-detail',
        loadChildren: () => import('./features/check-detail/check-detail.module').then((m) => m.CheckDetailModule),
        canActivate: [CompleteUserGuard, RoleGuard]
      },
      {
        path: 'requests',
        data: { role: UserRole.Customer },
        loadChildren: () => import('./features/requests/requests.module').then((m) => m.RequestsModule),
        canActivate: [CompleteUserGuard, RoleGuard]
      }
    ]
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren: () => import('./features/authentication/authentication.module').then((m) => m.AuthenticationModule)
      },
      {
        path: 'complete-profile',
        loadChildren: () => import('./features/complete-profile/complete-profile.module').then((m) => m.CompleteProfileModule),
        ...canActivate(redirectUnauthorizedToLogin)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { UserRole } from 'src/app/core/auth/data/auth.model';
import { NavItem } from './nav-item/nav-item';

export const CustomerSidebarNavItems: NavItem[] = [
  {
    id: 'customer-dashboard',
    displayName: 'Dashboard',
    iconName: 'layout-dashboard',
    route: '/customer-dashboard',
    roles: [UserRole.Customer]
  },
  {
    id: 'customer-collaborations',
    displayName: 'Collaborazioni',
    iconName: 'users',
    route: '/collaborations',
    roles: [UserRole.Customer]
  },
  {
    id: 'checks',
    displayName: 'Checks',
    iconName: 'checkup-list',
    route: '/checks',
    roles: [UserRole.Customer]
  }
];

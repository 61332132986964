<a id="requests-menu-item" mat-icon-button [routerLink]="['/requests']" aria-label="Richieste" title="Richieste">
  <mat-icon
    aria-hidden="false"
    [matBadge]="requestsCounter$ | async"
    [matBadgeHidden]="!!!(requestsCounter$ | async)"
    matBadgeSize="small"
    class="d-flex icon-20"
  >
    <i-tabler name="heart-handshake" class="icon-18 d-flex"></i-tabler>
  </mat-icon>
</a>

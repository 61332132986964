import { Action, createReducer, on } from '@ngrx/store';
import { IntroState } from './intro.model';
import { IntroActions } from './intro.actions';

export const initialState: IntroState = {
  loading: false,
  loaded: undefined,
  introInfo: undefined,
  error: undefined
};

export const introReducer = (state: IntroState | undefined, action: Action) => {
  return reducer(state, action);
};

const reducer = createReducer(
  initialState,
  on(IntroActions.getIntroInfo, (state) => ({
    ...state,
    loading: true,
    loaded: undefined,
    introInfo: undefined
  })),
  on(IntroActions.getIntroInfoSuccess, (state, { introInfo }) => ({
    ...state,
    loading: false,
    loaded: true,
    introInfo: introInfo
  })),
  on(IntroActions.getIntroInfoError, (state, { error }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: error
  })),
  on(IntroActions.saveIntroInfoSuccess, (state, { introType }) => {
    const introInfo = {
      ...state.introInfo,
      shownIntro: {
        ...state.introInfo.shownIntro,
        [introType]: true
      }
    };
    return {
      ...state,
      introInfo: introInfo
    };
  })
);
